// The following page generate the content for the university page. It will contain a brief profile of the 
// university, the hazing ratings, and a table that lists the hazing incidents that have occured at the university.

import React, { useEffect, useState } from 'react';
import "../App.css";
import Popup from 'reactjs-popup';
import { IncidentTable } from './IncidentTable';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from './Footer';

export function UniPage() {

    // stores fetched data for university name, array of incident information, unviversity information,
    const [uniName, setUniName] = useState('');
    const [incidentInfo, setIncidentInfo] = useState([]);
    const [uniInfo, setUniInfo] = useState([]);

    // current year is fetched so only incidents within the past 5 years are used for grade calculations
    const curYear = new Date().getFullYear()

    // constants set up so the user can navigate to the aboutRatings page from the pop up
    const nav = useNavigate;
    const navToIndex = () => {
        nav('/aboutRatings')
    };

    // useEffect to fetch existing Uni Names in database from endpoints
    React.useEffect(() => {
        fetch("/getData/uniInfo")
            .then((res) => res.json())
            .then((data) => setUniInfo(data.message));
    }, []);

    // useEffect to fetch existing Uni Incidents in database from endpoints
    React.useEffect(() => {
        fetch("/getData/incidents")
            .then((res) => res.json())
            .then((data) => setIncidentInfo(data.message));
    }, []);

    // useEffect to fetch existing Uni Names in database form endpoints
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    const [schoolsInIncidents, setSchoolsInIncidents] = useState([]);

    // useEffect to fetch existing incidents for Uni Name selected from endpoints 
    React.useEffect(() => {
        fetch("/getData/schoolsInIncidents")
            .then((res) => res.json())
            .then((data) => setSchoolsInIncidents(data.message));
    }, []);

    // constant to store the incident data from the past 5 years
    const fiveYearData = []

    // filter data to obtian only incidents from the past 5 years and add to fiveYearData array
    if (schoolsInIncidents.includes(uniName)) {
        // console.log(incidentInfo.length)

        for (let i = 0; i < incidentInfo.length; i++) {
            if (((curYear) - (incidentInfo[i].incidentYear)) <= 5) {
                fiveYearData.push(incidentInfo[i])
            }
        }
    }

    const [maxIncidents, setMaxIncidents] = useState([]);

    React.useEffect(() => {
        fetch("/getData/maxNumIncidents")
            .then((res) => res.json())
            .then((data) => setMaxIncidents(data.message));
    }, []);

    const [maxStudents, setMaxStudents] = useState([]);

    React.useEffect(() => {
        fetch("/getData/maxNumStudents")
            .then((res) => res.json())
            .then((data) => setMaxStudents(data.message));
    }, []);

    const FrequencyRating = ({ data }) => {
        // Normalized Scaling w higher emphasis on incidents
        const numStudents = uniInfo.numStudents
        const numOfIncidents = data.length

        // Normalize the number of students and incidents
        const normalizedStudents = numStudents / maxStudents;
        const normalizedIncidents = numOfIncidents / maxIncidents;

        // Define weightings for normalized incidents and students
        const incidentWeight = 0.75;
        const studentWeight = 0.25;

        // Calculate the weighted scaling factors
        const scalingFactorIncidents = normalizedIncidents * incidentWeight;
        const scalingFactorStudents = normalizedStudents * studentWeight;

        // Calculate the overall scaling factor
        // Subtract to ensure more students and fewer incidents results
        // in lower lambda
        const lambda = scalingFactorIncidents - scalingFactorStudents;
        console.log(lambda)

        if (lambda === 0) {
            return <img src="./imgs/aRating.png" alt="A rating" />;
        } else if (lambda <= 0.2) {
            return <img src="./imgs/bRating.png" alt="B rating" />;
        } else if (lambda <= 0.4) {
            return <img src="./imgs/cRating.png" alt="C rating" />;
        } else if (lambda <= 0.6) {
            return <img src="./imgs/dRating.png" alt="D rating" />;
        } else {
            return <img src="./imgs/fRating.png" alt="F rating" />;
        }
    }

    const SevRating = ({ data }) => {
        let intiCount = 0; // number of instances of intimindation incidents (in the past 5 years)
        let harCount = 0; // number of instances harassment incidents (in the past 5 years)
        let vioCount = 0; // number of instances of violent incidents (in the past 5 years)
        let nullCount = 0; // number of instances of null incidents (in the past 5 years)

        // iterates over each incident in the data array and increments count of the respective category of severity
        for (let i = 0; i < data.length; i++) {
            if (data[i].incidentSeverity == "Violence") {
                vioCount++;
            } else if (data[i].incidentSeverity == "Intimidation") {
                intiCount++;
            } else if (data[i].incidentSeverity == "Harassment") {
                harCount++;
            } else {
                nullCount++;
            }
        }

        console.log(intiCount, harCount, vioCount)

        const uniRatio = ((0.25 * intiCount) + (0.5 * harCount) + (1 * vioCount)) / fiveYearData.length
        console.log("uniRatio: " + uniRatio)

        if (fiveYearData.length != 0) {
            if (uniRatio >= 0 && uniRatio < 0.4) {
                return (
                    <img src='./imgs/aRating.png' alt='A rating' />
                )
            } else if (uniRatio >= 0.4 && uniRatio < 0.6) {
                return (
                    <img src='./imgs/bRating.png' alt='B rating' />
                )
            } else if (uniRatio >= 0.6 && uniRatio < 0.8) {
                return (
                    <img src='./imgs/cRating.png' alt='C rating' />
                )
            } else if (uniRatio >= 0.8 && uniRatio < 0.9) {
                return (
                    <img src='./imgs/dRating.png' alt='D rating' />
                )
            } else if (uniRatio >= 0.9 && uniRatio < 1.0) {
                return (
                    <img src='./imgs/fRating.png' alt='F rating' />
                )
            } else {
                return (
                    <img src='./imgs/noRating.png' alt='No rating' />
                )
            }
        } else {
            // if there are no instances within the past 5 years, return no rating
            return (
                <img src='./imgs/noRating.png' alt='No rating' />
            )
        }
    }

    // displays rating for hazing policies at the university 
    // grabs policyGrade data, which will be assigned by whoever enters the data from the
    // admin website 
    const PolicyRating = () => {
        if (uniInfo.policyGrade == 'A') {
            return (
                <img src='./imgs/aRating.png' alt='A rating' />
            )
        } else if (uniInfo.policyGrade == 'B') {
            return (
                <img src='./imgs/bRating.png' alt='B rating' />
            )
        } else if (uniInfo.policyGrade == 'C') {
            return (
                <img src='./imgs/cRating.png' alt='C rating' />
            )
        } else if (uniInfo.policyGrade == 'D') {
            return (
                <img src='./imgs/dRating.png' alt='D rating' />
            )
        } else if (uniInfo.policyGrade == 'F') {
            return (
                <img src='./imgs/fRating.png' alt='F rating' />
            )
        } else {
            return (
                <img src='./imgs/noRating.png' alt='No rating' />
            )
        }
    }


    // differentiates first render vs any renders following
    // allows us to set loading messagers for users 
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setTimeout(() => { setFirstRender(false); }, 450);
    }, []);

    // generates school profile with relevant information
    function SchoolProfile() {
        return (
            <div>
                <div className='profile-container'> {/* container for entire general profile info and school name */}
                    <div className='university-name'>
                        <h3><b>{uniName}</b></h3>
                    </div>
                    <div className='rating-container'>
                        <div class='flex-container-profile'>
                            <div className='school-profile'>
                                <h2>School Profile</h2>
                                <p><b>Type: </b>{uniInfo.schoolType}</p>
                                <p><b>Location: </b>{uniInfo.city}, {uniInfo.state}</p>
                                <p><b>Established: </b>{uniInfo.establishedDate}</p>
                                <p><b>Total Undergrate Students: </b>{uniInfo.numStudents}</p>
                                <p><b>Website: </b> <a class='blue-link' href={uniInfo.schoolWebsite}>{uniName}</a> </p>
                            </div>

                            <div className='greek-overview'>
                                <h2>Greek Life Overview</h2>
                                <p>{uniInfo.greekLifeOverview}</p>
                                <p>Learn more about {uniName} Greek Life <a class='blue-link' href={uniInfo.greekLife}>here</a>.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // constant that will appear as a pop up when user selects on learn more button 
    const FrequencyPopUp = () => (
        // the pop up component will display the details on how we calculate our ratings for frequency
        // the information is the same as the information on the aboutRatings page
        <Popup trigger={<button class='view-report-button pointer'> LEARN MORE </button>} modal> {/* triggers pop up */}
            <div>
                <div className='pop-up-container'>
                    <h3>Frequency of Incidents Rating</h3>
                    <p>
                        This category provides a grade with respect to
                        how often hazing incidents were reported at a
                        given university. In order to do so, our team at HazingInfo.org uses a custom
                        formula that takes into account:
                        <br></br>
                        <br></br>
                        <ol>
                            <li>Number of students at the university</li>
                            <li>Number of reported hazing incidents at the university in the past 5 years</li>
                        </ol>
                        <br />
                        The custom formula results in a score (lambda) that typically
                        falls in the range 0 - 0.6 where any value greater than that results in
                        the worst score (F).
                    </p>
                    {/* hazingIndex table for frequency is displayed */}
                    <img src='./imgs/freqScale.png' width={"100%"} alt='Frequency Index' />
                    <p><i>Learn more about our ratings in our <Link to="/aboutRatings" onClick={navToIndex}>Hazing Index</Link> tab.</i></p>
                </div>
            </div>
        </Popup>
    );
    const PolicyPopUp = () => (
        // the pop up component will display the details on how we calculate our ratings for hazing policies
        // the information is the same as the information on the aboutRatings page
        <Popup trigger={<button class='view-report-button pointer'> LEARN MORE </button>} modal>
            <div>
                <div>
                    <div className='pop-up-container'>
                        <h3>University Hazing Policies Rating</h3>
                        <p>
                            <p>Many universities have their own Anti-Hazing policies in addition to
                                the policies put in place by the state. This rating is an indication
                                of how comprehensive and effective the hazing policies at a given
                                university are.
                            </p>
                            <p>
                                A rating of “A”  would indicate that the University has
                                extremely comprehensive and strong Hazing policies defined and a rating
                                of “F” would indicate the University has extremely poor or no Hazing
                                policies defined. Since these ratings are completely data driven,
                                should we have a University for which enough data is not available,
                                a rating of “-” is provided to indicate the same.
                            </p>
                        </p>
                        <img src='./imgs/policyScale.png' width={"100%"} alt='Frequency Index' />
                        <p><i>Learn more about our ratings in our <Link to="/aboutRatings" onClick={navToIndex}>Hazing Index</Link> tab.</i></p>
                    </div>
                </div>
            </div>
        </Popup>
    );

    const SevPopUp = () => (
        // the pop up component will display the details on how we calculate our ratings for severity
        // the information is the same as the information on the aboutRatings page
        <Popup contentStyle={{ width: '75%', height: '90%' }} trigger={<button class='view-report-button pointer'> LEARN MORE </button>} modal>
            <div>
                <div className='sev-pop-up-container'>
                    {/* <div className='popop-content'> */}
                    <h3>Severity of Incidents Rating</h3>
                    <div>
                        <p>
                            <p>Hazing can be observed in several manners, ranging from simple tasks or
                                traditions to fatal and life threatening injuries. Ratings for this category
                                can help users understand how severe or intense the reported hazing incidents were
                                at a given university.</p>

                            <p>In our database, each hazing incident falls under one of the three categories for severity: (i) Intimidation; (ii) Harassment; and (iii) Violence.</p>
                            <p>If there is insufficient information to determine the severity category, the incident is assigned to the "Intimidation" category by default.</p>
                            <p>To calculate the grade for the severity of incidents, we use a weighted average approach. Each severity category is assigned a weight to reflect its relative importance:</p>
                            <ul>
                                <li>Intimidation: 0.25</li>
                                <li>Harassment: 0.5</li>
                                <li>Violence: 1</li>
                            </ul>
                            <p>After calculating the weighted averages, the final grades are assigned according to the following rubric:</p>
                            <img src='./imgs/sevScale.png' width={"40%"} alt='Frequency Index' />
                            <p>This grading system enables a standardized and objective measure of severity for hazing incidents, allowing for consistent evaluation and appropriate actions to be taken based on the assigned grades. The severity grade does not take the number of incidents at a University into account.</p>
                        </p>
                        <p><i>Learn more about our ratings in our <Link to="/aboutRatings" onClick={navToIndex}>Hazing Index</Link> tab.</i></p>
                    </div>
                </div>
            </div>
        </Popup>
    );

    // renders content for the actual hazing index for frequency, policy, and incident severity ratings 
    function RatingAndIncidents() {
        // handles instances in which a school exists does not have any hazing incidents
        if (!schoolsInIncidents.includes(uniName)) {
            return (
                <div>
                    <div className='rating-container' style={{ color: '#24305E' }}>
                        <h2>No Incident Data for {uniName}</h2>
                        <div>
                            <p>Due to the lack of incident data in our database,
                                we do not have enough information to create an HazingIndex
                                for <b>{uniName}</b>.
                            </p>
                            <p>Our team at HazingInfo is constantly working to find the most
                                credible data to help you make an informed decision. Check back later
                                for the most up to date list of Incidents and HazingIndex for <b>{uniName}</b>.
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            // returns the ratings for the school
            return (
                <div>
                    <div className='rating-container'>
                        <h2 style={{ color: '#24305E', paddingBottom: '1.5rem' }}>Hazing Index</h2>
                        <div className='rating-row'>
                            <div className='frequency'>
                                {/* initially renders loading comment for users and then displays data */}
                                {firstRender && <div>Loading...</div>}
                                {/* displays frequency rating by rendering functional componenet FreqeuncyRating 
                                which calculates, assigns, and displays frequency rating fron the past 5 years*/}
                                {!firstRender && <FrequencyRating data={fiveYearData} />}

                                <h3>Frequency of Incidents</h3>
                                <div style={{ position: 'absolute', right: 0 }}>
                                    {/* displays frequency rating details pop up as defined in functional component above */}
                                    <FrequencyPopUp />
                                </div>
                            </div>
                            <div className='policy'>
                                {/* displays policy rating by rendering functional componenet PolicyRating 
                                which assigns and displays frequency rating */}
                                {firstRender && <div>Loading...</div>}
                                {!firstRender && <PolicyRating />}
                                <h3>University Hazing Policies</h3>
                                <div style={{ position: 'absolute', right: 0 }}>
                                    {/* displays polciy rating details pop up as defined in functional component above */}
                                    <PolicyPopUp />
                                </div>
                            </div>

                            <div className='severity'>
                                {/* displays severity rating by rendering functional componenet SevRating 
                                which calculates, assigns, and displays incident severity rating */}
                                {firstRender && <div>Loading...</div>}
                                {!firstRender && <SevRating data={fiveYearData} />}
                                <h3>Severity of Incidents</h3>
                                <div style={{ position: 'absolute', right: 0 }}>
                                    <SevPopUp />
                                </div>
                            </div>
                        </div>
                        <p style={{ paddingTop: '2rem' }}><i>The following ratings are calculated based on data from the past <b>five</b> years.</i></p>

                    </div>

                    <div className='incident-table'>
                        <h2 style={{ color: '#24305E' }}>Hazing Incidents at {uniName}</h2>
                        <div className='table-container'>
                            {/* displays a table of all of the incidents that have occured at a unviersity that 
                            includes the following information: chapter name, charge date, hazing severity, incident type
                            and a view report button in which you can navigate to a page with more details on a specific incident  */}
                            <IncidentTable />
                        </div>
                    </div>
                </div>
            )
        }
    }

    // conditionally renders content on the page 
    // upon first render, the loading message is displayed while data is being fetched 
    // after first render, the actual component content is displayed
    // allows for a better UX so the user knows the page is loading and we are fetching data!
    return (
        <div>

            <div class='body-UniPage'>

                {firstRender &&
                    <div className='profile-container'>
                        <div>
                            <h3 className='university-name'>Loading School Profile...</h3>
                        </div>
                    </div>
                }
                {/* render School Profile component that contains university information after first render  */}
                {!firstRender && <SchoolProfile />}

                {firstRender &&
                    <div className='rating-container'>
                        <div>
                            <h3 className='frequency'>Loading HazingIndex & Incident Data...</h3>
                        </div>
                    </div>
                }
                {/* render RatingAndIncidents component that hazing index with ratings after first render  */}
                {!firstRender && <RatingAndIncidents />}


            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}