import React, { useEffect, useState } from 'react';
import { Footer } from './Footer';
import { useNavigate } from 'react-router-dom';

// DataCollection component
export function DataCollection() {

    // Scroll to the top of the page
    window.scrollTo(0, 0);

    const navigate = useNavigate();

    // State to store incident data
    const [incidentData, setIncidentData] = useState('');

    // Function to navigate to the hazing resources page
    const navigateToResourcesPage = () => {
        navigate('/hazingResources');
    };

    // Function to handle the download of incident data
    const handleIncidentDownload = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('/getData/donwloadIncidentData', requestOptions)
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'incidentData.json');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    }

    // Function to handle the download of school profile data
    const handleSchoolProfileDownload = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('/getData/donwloadSchoolData', requestOptions)
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'schoolProfileData.json');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    }

    // Render the DataCollection component
    return (
        <div class='body'>
            <h1 class='title-indent'>Data Collection</h1>

            <div class='text-box'>
                <h2>How is data collected?</h2>

                <p>
                    Several states in the United States have laws and legislation
                    in the area of Anti-hazing policies. For instance, in Washington
                    State, one of the objectives of Sam’s Law is requiring all
                    Public Universities in Washington State to publicly report all
                    conduct violations (including reported hazing incidents) in the
                    Greek System, along with pending investigations. Similarly,
                    Jack’s Law in the state of Arizona requires universities to
                    disclose the law to students and report incidents publicly as
                    well. The outcome of these laws ultimately result as very
                    credible sources of information for our team to work with.
                </p>

                <p>
                    Because hazing and hazing reporting laws vary substantially from
                    state to state, the information provided by institutions will also
                    naturally vary. Thus, more information and reported hazing incidents
                    for a given school is not necessarily a direct reflection of overall
                    hazing prevalence, but may be a result of state policies.
                </p>

                <p>
                    For more information on individual state hazing policies, visit our
                    Hazing Policies section in our
                    <span class='pointer blueText' onClick={navigateToResourcesPage}> Hazing
                        Resources</span> tab.
                </p>

                <p>
                    HazingInfo, in collaboration with StopHazing.org, uses a manual data
                    collection process to ensure that our data is accurate and
                    comprehensive. Generally, in adherence to each state’s hazing policies,
                    hazing reports are published by universities annually or bi-annually.
                    As new reports are published, the HazingInfo team works with StopHazing.org
                    to add new hazing incidents to our database. We focus our data entry on
                    these primary resources to ensure veracity and continuity in our data
                    collection method.
                </p>
            </div>


            <div class='text-box'>
                <h2>What if I notice an error?</h2>
                <p>
                    If you notice an error in our data please <a class='blueText pointer'>contact
                        us</a>. We will take another look and update the entry as needed.
                </p>
            </div>

            <div class='text-box'>
                <h2>Download our data:</h2>
                <p>
                    Our team at HazingInfo.org has collected data under two simple categories -
                    (i) School Profile; and (ii) Incident Data. Both of these categories come
                    together to contribute to our unique HazingIndex and also provide information
                    on the school in general. Use the respective buttons below to obtain the relevant
                    data.
                </p>
                <p>
                    All our data is available in <em>JavaScript Object Notation</em> (JSON) format. Text editors such as
                    Visual Studio Code (VS Code), Sublime Text etc. will allow for auto-formatting of the JSON data files,
                    ultimately making them easier to read.
                </p>
                <div><button class='download-button pointer' onClick={handleSchoolProfileDownload}>Download School Data</button></div>
                <div><button class='download-button pointer' onClick={handleIncidentDownload}>Download Incident Data</button></div>
            </div>
        </div>

    )
}