import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from './Footer';

export function WhatIsHazing() {
    return (
        <div>

            <div className='body'>
                <h1 className='title-indent'>What Is Hazing?</h1> {/*EDIT HERE: Title of the page */}
                <div className='text-box'>
                    <p> {/*EDIT HERE: Definition of hazing pargraph */}
                        Hazing covers a wide range of activities and
                        occurs across many different settings, including
                        Greek organizations, athletic teams, marching
                        bands and the military.
                    </p>

                    <p> {/*EDIT HERE: StopHazing.org description of hazing pargraph */}
                        Our partner, <a href='https://stophazing.org/issue/'>StopHazing.org</a>,
                        defines hazing as any activity expected of someone joining or
                        participating in a group that humiliates, degrades,
                        abuses or endangers them, regardless of that person’s
                        willingness to participate.
                    </p>

                    <p>Three key components define hazing:</p>
                    <ul> {/*EDIT HERE: A bullet list of the key components of hazing */}
                        <li>It occurs in a group context.</li>
                        <li>It involves humiliating, degrading or endangering behavior.</li>
                        <li>Even if the person is willingly participating, it is still considered hazing.</li>
                    </ul>

                    <p>A wide range of behaviors fit the definition of hazing:</p>
                    <center> {/*Image of the Spectrum of Hazing Behaviors from StopHazing.org  */}
                        <img className='spectrum-img' src='./imgs/hazingSpectrum.png' alt='Hazing Spectrum' />
                        <p>Source: <a href='https://stophazing.org/issue/'>StopHazing.org</a></p>
                    </center>

                    {/*EDIT HERE: Hazing consequences pargraph */}
                    <p>At its most extreme, hazing can result in death. But hazing can
                        also cause serious physical injuries as well as psychological
                        and emotional harm that can last a lifetime.
                    </p>
                </div>

            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
    
}