import React, { useState } from 'react';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'

// Remember to add props when handeling search
export function SchoolDropDown(props) {

    const navigate = useNavigate();
    const [selectSchool, setSelectSchool] = useState('');

    const navigateToUniPage = () => {
        if (selectSchool == '') {
            navigate('/');
        } else {
            navigate('/uniPage');
        }
    };

    const handleInputChange = (input) => {
        setSelectSchool(input.value)
    }

    const handleSearch = (e) => {
        var obj = {
            uniName: selectSchool
        };

        fetch("/getData/uniName", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(obj)
        });
    }

    const schoolNamesArray = (props.schoolNames.map(opt => ({ label: opt, value: opt })))

    const customStyles = {
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            color: state.isSelected ? "#000" : "#000",
            backgroundColor: state.isSelected ? "#a0a0a0" : "#fff",
        }),

        control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: "#fff",
            padding: "1px",
            border: "none",
            boxShadow: "none",
            borderRadius: '16px'
        }),
        singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#000" }),
    };

    const enterKey = () => {
        var input = document.getElementById("school-name");
        input.addEventListener("keyup", function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById("searchBtn").click();
            }
        });
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '500px', paddingRight: '1.5rem' }}>
                <Select
                    options={schoolNamesArray}
                    id='school-name'
                    placeholder="Select a University"
                    styles={customStyles}
                    onChange={handleInputChange}>
                    {/* <option value='Select a University'> -- Select a University -- </option>
                {props.schoolNames.map((name) => <option value={name}>{name}</option>)} */}
                </Select>
            </div>

            <div>
                <button id='searchBtn' class='search-button-home pointer' onClick={e => { handleSearch(); navigateToUniPage(); }}>SEARCH</button>
            </div>
            {enterKey}
        </div>
    );
}