import React, { useState } from 'react';
import { Footer } from './Footer';
import Table from 'react-bootstrap/Table';


// Const bullet List inside <tr>

const BulletList = ({ row }) => {
  if (row.grade == "A") {
    let measurement = row.measurement
    var splitMeasurement = measurement.split("\u2022");
    return (
      <ul>
        <li>{splitMeasurement[0]}</li>
        <li>{splitMeasurement[1]}</li>
        <li>{splitMeasurement[2]}</li>
        <li>{splitMeasurement[3]}</li>
        <li>{splitMeasurement[4]}</li>
        <li>{splitMeasurement[5]}</li>
      </ul>
    )
  } else if (row.grade == "B") {
    let measurement = row.measurement
    var splitMeasurement = measurement.split("\u2022");
    return (
      <span>
        {splitMeasurement[0]}
        <ul>
          <li>{splitMeasurement[1]}</li>
          <li>{splitMeasurement[2]}</li>
        </ul>
      </span>
    )
  } else {
    return (
      <span>{row.measurement}</span>
    )
  }
}


// Creates bullet points if data = {policyData}
const BulletInTable = ({ data }) => {

  if (data == policyData) {
    return (
      <Table hover striped>
        <thead>
          <tr>
            <th>Grade</th>
            <th>Measurement</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} style={{ backgroundColor: row.color }}>
              <td>{row.grade}</td>
              <td><BulletList row={row} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  } else {
    return (
      <Table hover striped>
        <thead>
          <tr>
            <th>Grade</th>
            <th>Measurement</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} style={{ backgroundColor: row.color }}>
              <td>{row.grade}</td>
              <td>{row.measurement}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
};

// creates tables that will further detail each letter grade for the given criteria
const CriteriaTable = ({ data }) => {
  return (
    <BulletInTable data={data} />
  );
};

const incidentData = [
  { grade: "A", measurement: "lambda = 0", color: "#d9ead3" },
  { grade: "B", measurement: "lambda ≤ 0.2", color: "#fff2cc" },
  { grade: "C", measurement: "lambda ≤ 0.4", color: "#fce5cd" },
  { grade: "D", measurement: "lambda ≤ 0.6", color: "#f4cccc" },
  { grade: "F", measurement: "lambda ≥ 0.6", color: "#e6b8af" },
  { grade: "-", measurement: "No data available", color: "#b7b7b7" },
];

const policyData = [
  { grade: "A", measurement: "Clear definition of hazing•Clear response and sanctions for confirmed hazing incidents•Easily accessible avenue for students to report hazing instances•Required Hazing Prevention Education•Substantial Hazing Prevention Educational Resources•Published and Updated Hazing Violation Report", color: "#d9ead3" },
  { grade: "B", measurement: "Meets most qualifications for an A except:•No publicly available Hazing Violation Report•Hazing Prevention Education is offered, but not required OR Hazing Prevention Education is not substantive", color: "#fff2cc" },
  { grade: "C", measurement: "Missing 1-3 qualifications for an A", color: "#fce5cd" },
  { grade: "D", measurement: "A hazing policy exists, but it is missing 3+ qualifications for an A OR is vague and unactionable", color: "#f4cccc" },
  { grade: "F", measurement: "No hazing policies", color: "#e6b8af" },
  { grade: "-", measurement: "No data available", color: "#b7b7b7" },
];

const severityData = [
  { grade: "A", measurement: "Weighted average of 0 ≤ Average < 0.4", color: "#d9ead3" },
  { grade: "B", measurement: "Weighted average of 0.4 ≤ Average < 0.6", color: "#fff2cc" },
  { grade: "C", measurement: "Weighted average of 0.6 ≤ Average < 0.8", color: "#fce5cd" },
  { grade: "D", measurement: "Weighted average of 0.8 ≤ Average < 0.9", color: "#f4cccc" },
  { grade: "F", measurement: "Weighted average of 0.9 ≤ Average < 1", color: "#e6b8af" },
  { grade: "-", measurement: "No data available", color: "#b7b7b7" },
];


export function AboutRatings() {
  window.scrollTo(0, 0);
  return (
    <div>

      <div class='body'>
        <h1 class='title-indent'>Hazing Index</h1>

        <div class='text-box'>
          <h2>What is the purpose of the Hazing Index?</h2>

          <p>
            The Hazing Index tool assigns letter rating to three important
            categories to help users quickly understand how well a university
            is doing in terms of hazing prevention. The categories include
            a) The frequency of reported hazing incidents; b) The
            effectiveness of the university's hazing policies; and c) The
            severity of hazing incidents that have occurred on campus. Since
            the categories are purely data driven, our team can ensure the
            grade assigned is based on credible data and not underlying bias,
            helping users make accurate and informed conclusions. The letter
            grades make it easy for users to compare different universities
            and quickly assess how different institutions are performing in
            terms of hazing prevention. By providing this information, the
            Hazing Index tool helps promote transparency and accountability
            in university policies and practices and supports the creation
            of safer and healthier campus environments.
          </p>
        </div>

        <div class='text-box'>
          <h2>How does the Hazing Index rating scale work?</h2>
          <div className='index-scale'>
            <img src='./imgs/aRating.png' alt='Green Circle A rating' width='75px' height='75px'></img>
            <img src='./imgs/bRating.png' alt='Light Green Circle B rating' width='75px' height='75px'></img>
            <img src='./imgs/cRating.png' alt='Green Circle A rating' width='75px' height='75px'></img>
            <img src='./imgs/dRating.png' alt='Green Circle A rating' width='75px' height='75px'></img>
            <img src='./imgs/fRating.png' alt='Green Circle A rating' width='75px' height='75px'></img>
            <img src='./imgs/noRating.png' alt='Green Circle A rating' width='75px' height='75px'></img>
          </div>

          <p>
            Each component of the hazing index uses a standard A - F letter
            rating scale, with A being the highest performing and F being
            the lowest performing for each category. Along with the letter
            rating, the scale also incorporates color coding that enables
            users to quickly associate the letter grades to a positive or
            negative connotation.
          </p>
        </div>

        <div class='text-box'>
          <h2>Components of the Hazing Index</h2>

          <p>
            The hazing index is comprised of ratings for three distinct categories:
            frequency of incidents, university hazing policies, and severity of
            incidents. Each rating is further described below:
          </p>
        </div>
        <div class='text-box'>
          <h3>Frequency of Incidents</h3>
          <p class='text-box-indent'>
            <p>
              This category provides a grade with respect to
              how often hazing incidents were reported at a
              given university. In order to do so, our team at HazingInfo.org uses a custom
              formula that takes into account:
              <br></br>
              <br></br>
              <ul>
                <li>Number of students at the university</li>
                <li>Number of reported incidents at the university in the past 5 years</li>
              </ul>
            </p>
            The custom formula results in a score (lambda) that typically
            falls in the range 0 - 0.6 where any value greater than that results in
            the worst score (F).
          </p>
          <div className='criteria-table'>
            <CriteriaTable data={incidentData} />
          </div>

          <h3>University Hazing Policies</h3>
          <p class='text-box-indent'>
            Several universities have their own Anti-Hazing policies in addition to
            the policies put in place by the state. This category is an indication
            of how comprehensive and effective the hazing policies at a given
            university are. A rating of “A”  would indicate that the University has
            extremely comprehensive and strong Hazing policies defined and a rating
            of “F” would indicate the University has extremely poor or no Hazing
            policies defined. Since these ratings are completely data driven,
            should we have a University for which enough data is not available,
            a rating of “-” is provided to indicate the same.
          </p>
          <div className='criteria-table'>
            <CriteriaTable data={policyData} />
          </div>
          <h3>Severity of Incidents</h3>
          <p class='text-box-indent'>
            <p>Hazing can be observed in several manners, ranging from simple tasks or
              traditions to fatal and life threatening injuries. Ratings for this category
              can help users understand how severe or intense the reported hazing incidents were
              at a given university.</p>

            <p>In our database, each hazing incident falls under one of the three categories for severity: (i) Intimidation; (ii) Harassment; and (iii) Violence.</p>
            <p>If there is insufficient information to determine the severity category, the incident is assigned to the "Intimidation" category by default.</p>
            <p>To calculate the grade for the severity of incidents, we use a weighted average approach. Each severity category is assigned a weight to reflect its relative importance:</p>
            <ul>
              <li>Intimidation: 0.25</li>
              <li>Harassment: 0.5</li>
              <li>Violence: 1</li>
            </ul>
            <p>
              This grading system enables a standardized and objective measure of severity for hazing incidents, allowing for consistent
              evaluation and appropriate actions to be taken based on the assigned grades. The severity grade does not take the number of incidents at a
              University into account.
            </p>
            <p>After calculating the weighted averages, the final grades are assigned according to the following rubric:</p>
          </p>
          <div className='criteria-table'>
            <CriteriaTable data={severityData} />
          </div>


        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}