import React, { useState } from 'react';
import { SchoolDropDown } from './SchoolDropDown';
import "../App.css";

// Remember to add props when handeling search
export function Database() {

    const [searchInput, setSearchInput] = useState('');

    // School Name State
    const [schoolNames, setSchoolNames] = React.useState([]);

    // useEffect to fetch School Names
    React.useEffect(() => {
        fetch("/getData/schoolNames")
            .then((res) => res.json())
            .then((data) => setSchoolNames(data.message));
    }, []);

    return (
        <center>
            <div>
                <div class='blue-rect'>
                    <h1 class='blue-rect-text'>Search our Hazing Database</h1>

                    <div class="row">
                        <div class="column"><SchoolDropDown schoolNames={schoolNames} /></div>
                    </div>

                </div>
            </div>
        </center>
    )
}