import React, { useState } from 'react';
import { SchoolDropDown } from './SchoolDropDown';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import { Footer } from './Footer';
import { Link } from 'react-router-dom';

// Remember to add props when handeling search
export function HomePage() {

    // School Name State
    const [schoolNames, setSchoolNames] = useState([]);

    // useEffect to fetch School Names
    React.useEffect(() => {
        fetch("/getData/schoolNames")
            .then((res) => res.json())
            .then((data) => setSchoolNames(data.message));
    }, []);

    const scrollDown = () => {
        window.scrollTo(0, 850)
    }
    return (

        <div>
            <center>
                <div class='body-home' style={{ width: '100%', minHeight: '100%', padding: '0 0px' }}>
                    <div class='blue-rect'>
                        {/* <img class='main-logo' src='./imgs/logoTransparent2.png' width="25%" alt='What is Hazing?' /> */}
                        <div class="landing-container" style={{ width: '100% ' }}>
                            <div className='landing-row'>
                                <h1 class='blue-rect-text'>Welcome to <img className='h-logo' src='./imgs/fav.png'></img>azingInfo.org</h1>
                                <p className='landing-text'>
                                    HazingInfo.org is a free, searchable database of reported hazing incidents in the United States
                                    occurring at colleges and universities with fraternities and sororities.
                                    {/* <br></br>
                                Use our tool to search by school name for hazing incidents that have resulted in formal findings, sanctions 
                                or penalties.
                                <br></br>
                                Our hazing incidents database is a work in progress. We continue to add new states, new incidents and new 
                                institutions as information becomes available. Please check back frequently. */}
                                </p>
                                <div class="column-home"><SchoolDropDown schoolNames={schoolNames} /></div>
                            </div>
                            <div className='arrow-container'>
                                <p>Learn more about HazingInfo.org below</p>
                                <img class='pointer' onClick={scrollDown} style={{ width: '14%' }} src="./imgs/arrow2.png"></img>
                            </div>

                        </div>
                    </div>
                </div>
            </center>
            <div class="wave"></div>

            <div className='home-row-container'>
                <div className='home-col'>
                    <h1 className='home-header'>What is Hazing?</h1>
                    <h5>Learn about the 3 components of hazing <Link to="/WhatIsHazing">here</Link>.</h5>
                </div>

                <div className='home-col' style={{ alignItems: 'flex-end' }}>
                    <img
                        className='home-image'
                        src="./imgs/home1.png"
                        alt="photo of student with a backpack"
                    ></img>
                </div>
            </div>

            <div className='home-row-container'>
                <div className='home-col' style={{ alignItems: 'flex-start' }}>
                    <img
                        className='home-image'
                        src="./imgs/home2.png"
                        alt="photo of student with a backpack"
                    ></img>
                </div>

                <div className='home-col'>
                    <h1 style={{ textAlign: 'right' }} className='home-header'>Hazing Resources</h1>
                    <h5 style={{ textAlign: 'right' }}>Find state hazing policies, hazing prevention information and more <Link to="/HazingResources">here</Link>.</h5>
                </div>
            </div>

            <div className='home-row-container' style={{ paddingBottom: '10rem' }}>
                <div className='home-col'>
                    <h1 className='home-header'>About Us</h1>
                    <h5>Inspired by the story of Sam Martinez, we aim to empower college students and families with timely information about hazing. Find out more about us <Link to="/AboutUs">here</Link>.</h5>
                </div>

                <div className='home-col' style={{ alignItems: 'flex-end' }}>
                    <img
                        className='home-image'
                        src="./imgs/home3.png"
                        alt="photo of Sam Martinez"
                    ></img>
                </div>
            </div>

            <div>
                <Footer />
            </div>

        </div>
    )

}