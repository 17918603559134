// client/src/components/App.js

import React from "react";
import { Routes, Router, redirect, Navigate, useNavigate, Route, Outlet, useLocation } from 'react-router-dom';
import "../App.css";
import { NavBar } from "./NavBar";
import { HomePage } from "./HomePage";
import { HazingResources } from "./HazingResources";
import { Database } from "./Database"
import { UniPage } from "./UniPage";
import { DataCollection } from "./DataCollection";
import { AboutRatings } from "./AboutRatings";
import { IncidentReport } from "./IncidentReport";
import { Footer } from "./Footer";
import { AboutUs } from "./AboutUs";
import { WhatIsHazing } from "./WhatIsHazing";
import { PrivacyPolicy } from "./PrivacyPolicy";

function App() {

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="*" element={[<HomePage />]} />
        <Route path="homePage" element={[<HomePage />]} />
        <Route path="database" element={[<Database />]} />
        <Route path="hazingResources" element={[<HazingResources />]} />
        <Route path="aboutUs" element={[<AboutUs />]} />
        <Route path="uniPage" element={[<UniPage />]} />
        <Route path="dataCollection" element={[<DataCollection />]} />
        <Route path="aboutRatings" element={[<AboutRatings />]} />
        <Route path="report" element={[<IncidentReport />]} />
        <Route path="whatIsHazing" element={[<WhatIsHazing />]} />
        <Route path="privacyPolicy" element={[<PrivacyPolicy />]} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;