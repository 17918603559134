import React from 'react';
import "../App.css";
import { Footer } from './Footer';
import { Link, useNavigate } from 'react-router-dom';


// creates cards component to be reused in a row for resources
const ResourceCard = ({ logo, title, text, link, imgLink }) => {
  return (
    <div className="cards">
      <div className="card-top">
        <a href={imgLink}><img src={logo} alt={title} className="card-logo" /></a>
        <h2 className="card-title">{title}</h2>
      </div>
      <div className="card-description">
        <p className="card-text">{text}</p>
      </div>
      <div className="card-foot">
        <a href={link} className="card-link">Learn More</a>
        <a href={imgLink}><img src='./imgs/arrow.png' height='20px' width='20px'></img></a>
      </div>
    </div>
  );
};

const nav = useNavigate;
const navToWhatIsHazing = () => {
  nav('/whatIsHazing')
}

const navToPrivacyPolicy = () => {
  nav('/privacyPolicy');
};

const WhatIsHazingCard = ({ logo, title, text }) => {
  return (
    <div className="cards">
      <div className="card-top">
        <Link className="card-logo" to="/whatIsHazing" onClick={navToWhatIsHazing}><img src={logo} alt={title} className="card-logo" /></Link>
        <h2 className="card-title">{title}</h2>
      </div>
      <div className="card-description">
        <p className="card-text">{text}</p>
      </div>
      <div className="card-foot">
        <Link className='card-link' to="/whatIsHazing" onClick={navToWhatIsHazing}>Learn More</Link>
        <Link to="/whatIsHazing" onClick={navToWhatIsHazing}><img src='./imgs/arrow.png' height='20px' width='20px'></img></Link>
      </div>
    </div>
  );
};


export default ResourceCard;

// Remember to add props when handeling search
export function HazingResources() {

  return (
    <div class='body'>
      <h1 class='title-indent'>Hazing Resources</h1>

      <div class='text-box'>
        <p>
          Hazing can manifest in many different ways and under many different circumstances; thus,
          addressing hazing becomes significantly more difficult. The following resources have been
          curated in an effort to address gaps in knowledge surrounding hazing and furthermore
          enable students, teachers, administration, parents and others with the knowledge necessary
          to address and prevent hazing in their unique settings. View the following resources to
          learn more about and how to prevent hazing:
        </p>
      </div>

      <div className='resource-container'>
        <ResourceCard
          logo="./imgs/policy.png"
          title="Policy"
          text="Hazing policies vary from state to state. Click below to learn more about your or another 
                    state’s hazing policies"
          link="https://stophazing.org/policy/state-laws/"
          imgLink='https://stophazing.org/policy/state-laws/'
        />
        <ResourceCard
          logo="./imgs/stophazing.png"
          title="StopHazing.org"
          text="Want to learn more about hazing or need professional guidance? Click below to get connected"
          link="https://stophazing.org"
          imgLink='https://stophazing.org'
        />
        <WhatIsHazingCard
          logo="./imgs/report.png"
          title="What is Hazing?"
          text="Hazing covers a wide range of activities and
                    occurs across many different settings"
        />
      </div>

      <div>
        <div id="footer-resources">
          <footer>
            <a className='white-text pointer' onClick={navToPrivacyPolicy}>Privacy Policy</a>
            <p><a className='white-text pointer' href='mailto:info@hazinginfo.org'>Contact Us</a></p>
          </footer>
        </div>
      </div>
    </div>
  )
}