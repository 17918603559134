import React from 'react';
import "../App.css";
import { useNavigate } from 'react-router-dom';

// Footer component
export function Footer() {
    const navigate = useNavigate();

    // Function to navigate to the data collection page
    const navToPrivacyPolicy = () => {
        navigate('/privacyPolicy');
    };

    // Render the footer component
    return (
        <div className="footer">
            <footer>
                <a className='white-text pointer' onClick={navToPrivacyPolicy}>Privacy Policy</a>
                <p><a className='white-text pointer' href='mailto:info@hazinginfo.org'>Contact Us</a></p>
            </footer>
        </div>
    );
}
