//About Us Page to describe project overview, project mission, team members role/linkedIn, and sponsers 

import React, { useState } from 'react';
import "../App.css";
import { Footer } from './Footer';

export function AboutUs() {
    // names of the use State props
    const [selectedTab, setTab] = useState(1);

    // Functionallity of clickable tab
    const tabClick = (tabIndex) => {
        setTab(tabIndex);
    };

    window.scrollTo(0, 0)

    return (
        <div>

            <div class="tabs">
                {/* Makes the first tab clickable and checks if it is the correct 
               tab then changes to the page when tab button is clicked.*/}
                <input class="input" name="tabs" type="radio" id="tab-4"
                    checked={selectedTab === 1}
                    onChange={() => tabClick(1)}
                />

                {/*Content for first tab: Overview */}
                <label class="label" for="tab-4">Overview</label>
                <div class="panel">
                    <h1 class="about-titles">Project Overview</h1>
                    <div className='mission-container'>
                        <p className='mission-text'> {/*EDIT HERE: the project overview paragraph */}
                            <p>HazingInfo.org was born out of a need for transparent university hazing information. Our Sponsor, the Houtz-Martinez family, tragically lost their son to hazing when he pledged a fraternity at Washington State University. Because of a lack of safety information available, the family had no idea of the risks at this chapter or university. Neither our sponsor nor our team could find a centralized resource that evaluated safety information in a way that could be preventative of these fatal outcomes that happen nearly every year. </p>
                            <p>Our resulting database and web application are designed to address the needs of our primary stakeholders and users: prospective Greek life students and their families. We also acknowledge that our solution must meet the needs of secondary stakeholders including current Greek life students, Greek life leadership, and other leaders such as chapter advisors. With this information in mind, the primary functionality of our website is to not only aggregate but also evaluate hazing information of college campuses based on hazing frequency, severity, and policy.
                            </p>
                            <p>The first thing our team did was in depth research to see what information was present, and what was missing. We partnered with <a href="https://www.stophazing.org">StopHazing.org</a>, a nonprofit organization focused on hazing research and prevention, to help collect data about hazing incidents and provide quality educational resources to our users. We decided to partner with this organization, and our team cleaned up and added significant amounts of data for Washington State and Texas schools. We simultaneously built a backend that would process this data in real time to give ratings on frequency, severity, and policy - so that our users could gauge the safety of a campus, and so that Greek leadership has incentive to change hazing culture rather than hide it.
                            </p>
                            <p>Our project's primary outcome is that it provides prospective Greek students and their families with clear hazing information that allows them to make informed decisions around their safety. This will result in a decrease in prevalence of negative outcomes of hazing; lowered self-esteem, alcoholism, violence, injury, and death. On top of this, as our project becomes more intertwined with Greek Life leadership, chapters will be more inclined to change their hazing culture rather than disguise it - as they may lose potential members concerned about their safety. Our project is not designed to diminish Greek Life, but rather to change its culture so that there are no more cases like Sam’s.
                            </p>

                        </p>
                    </div>
                </div>

                {/* Makes the second tab clickable and checks if it is the correct 
               tab then changes to the page when tab button is clicked. */}
                <input class="input" name="tabs" type="radio" id="tab-1"
                    checked={selectedTab === 2}
                    onChange={() => tabClick(2)}
                />
                {/*Content for second tab: Mission */}
                <label class="label" for="tab-1">Mission</label>
                <div class="panel">
                    <h1 class="about-titles">Our Mission</h1>
                    <div className='mission-container'>
                        <p className='mission-text'> {/*EDIT HERE: Mission Tab paragraph */}
                            Sam Martinez was a 19-year-old business student at Washington State University in 2019 when he was hazed to death just weeks into his
                            freshman year. Sam was funny and spontaneous, a loyal friend, a gifted athlete, a youth coach and a loving son and brother.
                            His parents, Jolayne Houtz and Hector Martinez, launched <a href="./HomePage">HazingInfo.org</a> in
                            partnership with students from the University of Washington Information School to ensure incoming college students and their families
                            have current, accurate information about student organizations so they can make informed decisions about whether to join.
                            <br></br>
                            <br></br>
                            Jolayne and Hector are actively working to change policies at the state and national levels to expand education and training about hazing
                            prevention and to increase transparency about and accountability for hazing incidents.
                        </p>
                        <div class='flex-container'>
                            {/* Picture for homepage */}
                            <div class='col rounded-box martin-img'>
                                <img src='./imgs/Sam.png' alt='Sam Martinez' />
                                <p className='martin-profile'>Sam Martinez (2000 - 2019)</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Makes the third tab clickable and checks if it is the correct 
                tab then changes to the page when tab button is clicked.*/}
                <input class="input" name="tabs" type="radio" id="tab-2"
                    checked={selectedTab === 3}
                    onChange={() => tabClick(3)}
                />

                {/*Content for third tab: Our Team */}
                <label class="label" for="tab-2">Team</label>
                <div class="panel">
                    <h1 class="about-titles">Our Team</h1>
                    <p style={{ paddingLeft: '4rem', paddingRight: '4rem' }}> {/*EDIT HERE:  description of the team and their goal */}
                        The HazingInfo team is comprised of 10 Informatics students from the University of Washington Information School.
                        We started on this initiative as a part of our final Capstone project, and are extremely passionate about the
                        problem of hazing and providing accurate data and educational resources to students, families, and current
                        university students in order to keep students safe and informed, and help prevent hazing incidents in the future.
                    </p>
                    <p style={{ paddingLeft: '4rem', paddingRight: '4rem' }}> {/*EDIT THIS BLOCK to update this paragraph */}
                        Below are the developers that helped in the develoment of <a href="./HomePage">HazingInfo.org</a>.
                    </p>

                    <div class='text-box'>
                        {/*static data */}
                        <div className='flex-team-row'>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/PC-pp.png' alt='PC profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Pragnath Chintalapati</p>{/*EDIT HERE: team member name */}
                                <p className='team-profile'>Full-Stack Developer</p>{/*EDIT HERE: team memeber role */}
                                <p className='team-profile'><a href="https://www.linkedin.com/in/pragnathc163/">Linkedin</a></p>{/*EDIT HERE: team member linkedIn page link */}
                            </div>

                            <div className='col rounded-box team-img'>
                                <img src='./imgs/CC-pp.png' alt='CC profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Cynthia Chung</p>
                                <p className='team-profile'>Product Manager & Front End Developer</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/cynthiahjchung1/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/BB-pp.png' alt='BB profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Ben Bunyatipanon</p>
                                <p className='team-profile'>UX Designer</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/benbunya/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/AK-pp.png' alt='AK profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Amaya Kejriwal</p>
                                <p className='team-profile'>Product Manager</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/amayakejriwal/">Linkedin</a></p>
                            </div>
                            <div className=' col rounded-box team-img'>
                                <img src='./imgs/SJ-pp.png' alt='Sid profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Sid Jayadev</p>
                                <p className='team-profile'>UI/UX Designer</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/siddharth-jayadev-498480241">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/DG-pp.png' alt='DG profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Drew Grimstead</p>
                                <p className='team-profile'>Front End & Information Security</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/drew-grimstead/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/BBush-pp.png' alt='BBush profile' />
                                <p className='team-profile-name'>Brielle Bush</p> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile'>Project Manager & UX Research & Design</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/briellebush/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/JN-pp.png' alt='Jn profile' />
                                <p className='team-profile-name'>Julia Nicholas</p> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile'>Back-end Developer & Data Analyst</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/julia-nichols12/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/LAG-pp.png' alt='LAG profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Liliana Alvarado Garcia</p>
                                <p className='team-profile'>UX Design & Data Analyst</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/liliana-alvarado-garcia-325a01g/">Linkedin</a></p>
                            </div>
                            <div className='col rounded-box team-img'>
                                <img src='./imgs/JKF-pp.png' alt='JKF profile' /> {/*EDIT HERE: for each team member , their role, and linkedIn page link */}
                                <p className='team-profile-name'>Jonathan K. Farias</p>
                                <p className='team-profile'>UX Design & Back-end Developer</p>
                                <p className='team-profile'><a href="https://www.linkedin.com/in/farias29/">Linkedin</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* code for sponsor tab */}
                {/* Makes the fourth tab clickable and checks if it is the correct 
               tab then changes to the page when tab button is clicked. */}
                <input class="input" name="tabs" type="radio" id="tab-3"
                    checked={selectedTab === 4}
                    onChange={() => tabClick(4)}
                />

                {/*Content for fourth tab: Sponsors*/}
                <label class="label" for="tab-3">Sponsors</label>
                <div class="panel">
                    <h1 class="about-titles">Our Sponsors</h1>
                    <div className='mission-container'>
                        <p className='mission-text'> {/*EDIT HERE: Sponsor description pargraph */}
                            HazingInfo.org was created as a 2023 capstone project at the Information School at the University of Washington.
                            Sponsors provide our team with guidance and resources to ensure HazingInfo.org will continue to be updated,
                            maintained and expanded for years to come. The following sponsors each play a unique role in
                            HazingInfo.org:
                        </p>
                    </div>

                    {/*Content about Jolayne and Hector */}
                    <div className='sponsor-container'>
                        <img src='./imgs/martinez.png' alt='photo of Jolayne Houtz and Hector Martinez'></img>
                        <div className='sponsor-col'>
                            <h3 style={{ color: '#24305E' }}>Jolayne Houtz and Hector Martinez</h3> {/*EDIT HERE: Jolayne and Hector paragraph */}
                            <p>The Martinez family serves as the primary driver of HazingInfo.org. The Martinez family
                                continues to coordinate with stakeholders, oversee communication, collect data, and ensure
                                data integrity.
                            </p>
                        </div>
                    </div>

                    {/* Content about StopHazing.org */}
                    <div className='sponsor-container'>
                        <img src='./imgs/stophazing2.png' alt='stophazing.org logo'></img>
                        <div className='sponsor-col'>
                            <h3 style={{ color: '#24305E' }}>StopHazing.org</h3> {/*EDIT HERE: StopHazing.org paragraph */}
                            <p>StopHazing.org, a research entity dedicated to research, resource sharing, and the develoment of data-driven hazing prevention strategies,
                                will support HazingInfo.org in the aspects of research and data collection. They will also provide support for proper data collection methods,
                                research strategies, and educational resources.
                            </p>
                        </div>
                    </div>

                    {/*Content about SeaMonster Studio */}
                    <div className='sponsor-container'>
                        <img src='./imgs/seamonster.png' alt='Sea monster studio logo'></img>
                        <div className='sponsor-col'>
                            <h3 style={{ color: '#24305E' }}>SeaMonster Studios</h3> {/*EDIT HERE: SeaMonster Studio paragraph */}
                            <p>SeaMonster Studios, a Seattle based creative agency, will help support technical aspects of HazingInfo.org
                                In addition to hosting HazingInfo.org, SeaMonster will also provide mentorship for long term technical implementation.
                            </p>
                        </div>
                    </div>

                    {/*Content about Information School */}
                    <div className='sponsor-container'>
                        <img src='./imgs/ischool.png' alt='Information School logo'></img>
                        <div className='sponsor-col'>
                            <h3 style={{ color: '#24305E' }}>The Information School</h3> {/*EDIT HERE: Information School paragraph */}
                            <p>An undergraduate/graduate school at the University of Washington the iSchool will oversee all future academic projects related
                                to HazingInfo.org. HazingInfo.org is a project that was initially created for a senior capstone project for the iSchool in 2023.
                            </p>
                        </div>
                    </div>
                </div>
                {/* {teammembers}  === To call the list of team members dynamically -- for later*/}
            </div>

            <div>
                <Footer />
            </div>
        </div>


    )
}
