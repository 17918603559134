/* The NavBar.js component generates the Navigation bar for the website.
The navigation bar conatains links to other pages within the website and allows
users to naviagte through the different pages inside the website.*/

import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import "../App.css";
import { useNavigate } from 'react-router-dom';

// Navigation Bar Component
export function NavBar() {
    const navigate = useNavigate();

    // Function to navigate to the data collection page
    const navigateToDataCollection = () => {
        navigate('/dataCollection');
    };

    // Function to navigate to the Ratings page
    const navigateToAboutRatings = () => {
        navigate('/aboutRatings');
    };

    //Renders the Navigation Bar component
    return (
        <nav id="navbar">
            <div class='nav-wrapper'>
                <a class="logo" href="/">
                    <div class="logo-image">
                        <img src='./imgs/logoTransparent2.png' class="img-fluid" alt='Logo stating Hazing Info in white bold letters?'></img>
                    </div>
                </a>
                <ul id="menu">
                    <li><a href='/whatIsHazing'>What Is Hazing?</a></li>
                    <li><a href='/hazingResources'>Hazing Resources</a></li>
                    <li><a href='aboutUs'>About Us</a></li>
                    <li>
                        <a class='white-text'>
                            <Dropdown>
                                <Dropdown.Toggle variant="Secondary" class="navDrop" style = {{ color : '#FFFFFF', fontWeight : '600', fontSize : '14px', marginTop : '-2px' }}>
                                    OUR DATA
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" variant='light'>
                                    <Dropdown.Item style = {{ color: '#24305E', width: 'auto' }} onClick={navigateToDataCollection}>Data Collection</Dropdown.Item>
                                    <Dropdown.Item style = {{ color: '#24305E', width: 'auto' }} onClick={navigateToAboutRatings}>Hazing Index</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </a>
                    </li>
                </ul>
            </div>
        </nav >
    )
}
