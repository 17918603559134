import React, { useState } from 'react';
import "../App.css";
import { Footer } from './Footer';
import { IncidentTable } from './IncidentTable';
import { useNavigate } from 'react-router-dom';

export function IncidentReport() {

    const [uniName, setUniName] = useState('');
    const [reportData, setReportData] = useState([]);

    // for back button
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    // useEffect to fetch Uni Name
    React.useEffect(() => {
        fetch("/getData/selectedUniName")
            .then((res) => res.json())
            .then((data) => setUniName(data.message));
    }, []);

    // useEffect to fetch Report Data
    React.useEffect(() => {
        fetch("/getData/selectedReportData")
            .then((res) => res.json())
            .then((data) => setReportData(data.message));
    }, []);
    // console.log(reportData)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const missingElements = [String]

    const greekSymbol = () => {
        if (reportData.greekSymbol == "") {
            missingElements.push("Greek Symbol")
            return (
                <span>
                    <h5>Chapter Name</h5>
                    <p>{reportData.greekOrganizationName} at the {uniName}</p>
                </span>
            )
        } else {
            return (
                <span>
                    <h5>Chapter Name</h5>
                    <p>{reportData.greekOrganizationName} ({reportData.greekSymbol}) at {uniName}</p>
                </span>
            )
        }
    }

    // console.log(greekSymbol)

    const incidentType = () => {
        if (reportData.incidentType == "") {
            missingElements.push("Incident Type")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <h5>Incident Type</h5>
                    <p>{reportData.incidentType}</p>
                </div>
            )
        }
    }

    const incidentSeverity = () => {
        if (reportData.incidentSeverity == "") {
            missingElements.push("Incident Severity")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <h5>Incident Severity</h5>
                    <p>{reportData.incidentSeverity}</p>
                </div>
            )
        }
    }

    const incidentDescription = () => {
        if (reportData.incidentDescription == "") {
            missingElements.push("Incident Description")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <h5>Incident Description</h5>
                    <p>{reportData.incidentDescription}</p>
                </div>
            )
        }
    }

    const investEntity = () => {
        if (reportData.investEntity == "") {
            missingElements.push("Investigation Entity")
            return (
                <span></span>
            )
        } else {
            return (
                <span>
                    <b>Investigating Entity:</b> {reportData.investEntity}<br />
                </span>
            )
        }
    }

    const dateInvestigation = () => {
        if (reportData.dateInvestigation == "") {
            missingElements.push("Investigation Date Initiated")
            return (
                <span></span>
            )
        } else {
            return (
                <span>
                    <b>Investigating Date Initiated:</b> {reportData.dateInvestigation}<br />
                </span>
            )
        }
    }

    const dateInvestigationEnded = () => {
        if (reportData.dateInvestigationEnded == "") {
            missingElements.push("Investigation Date Ended")
            return (
                <span></span>
            )
        } else {
            return (
                <span>
                    <b>Investigating Date Concluded:</b> {reportData.dateInvestigationEnded}<br />
                </span>
            )
        }
    }

    const dateCharge = () => {
        if (reportData.dateCharge == "") {
            missingElements.push("Charge Date")
            return (
                <span></span>
            )
        } else {
            return (
                <span>
                    <b>Charge Date:</b> {reportData.dateCharge}
                </span>
            )
        }
    }

    const violations = () => {
        if (reportData.violations == "") {
            missingElements.push("Conduct Violations")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <p><b>Guilty of the Following Conduct Violations:</b></p>
                    <p>{reportData.violations}</p>
                </div>
            )
        }
    }

    const sanctions = () => {
        if (reportData.sanctions == "") {
            missingElements.push("Sanctions")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <p><b>Sanctions:</b></p>
                    <p>{reportData.sanctions}</p>
                </div>
            )
        }
    }

    const outcomeLetter = () => {
        if (reportData.outcomeLetter == "") {
            missingElements.push("Outcome Letter")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <p><b>Outcome Letter:</b></p>
                    <p>{reportData.outcomeLetter}</p>
                </div>
            )
        }
    }

    const newsCoverage = () => {
        if (reportData.newsCoverage == "") {
            missingElements.push("News Coverage")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <p><b>News Coverage:</b></p>
                    <p>{reportData.newsCoverage}</p>
                </div>
            )
        }
    }

    const extraLinks = () => {
        if (reportData.extraLinks == "") {
            missingElements.push("Extra Links")
            return (
                <span></span>
            )
        } else {
            return (
                <div>
                    <p><b>Extra Link: </b><a href={reportData.extraLinks}>View Report</a></p>
                </div>
            )
        }
    }


    return (
        <div>
            {/*INCIDENT PAGE START: move this and link to incidents table */}
            <div className='incident-report body'>
                <h2 className='incident-title'>Hazing Incident Report</h2>

                {greekSymbol()}
                {incidentType()}
                {incidentSeverity()}
                {incidentDescription()}
                <h5>Formal Investigation</h5>
                <p>
                    {investEntity()}
                    {dateInvestigation()}
                    {dateInvestigationEnded()}
                    {dateCharge()}
                </p>
                <h5>Investigation Results</h5>
                {violations()}
                {sanctions()}
                <h5>Other Resources</h5>
                {outcomeLetter()}
                {newsCoverage()}
                {extraLinks()}
                <div style={{ paddingLeft: '5rem', paddingTop: '1rem' }}>
                    <button class='view-report-button pointer' onClick={goBack}>BACK</button>
                </div>
            </div>

            <div>
                <Footer />
            </div>
            {/*INCIDENT PAGE END: move this and link to incidents table */}
        </div>
    )
}